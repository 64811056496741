window.onscroll = function() {
    if (document.location.pathname != '/member/main/trainings') {
        return;
    }
    let positionTop = document.documentElement.scrollTop || document.body.scrollTop
    sessionStorage.setItem('trainings-scroll_v', positionTop);
};

function getParams() {
  var params = location.href
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params_obj = {};
  let match;
  while (match = regex.exec(params)) {
    params_obj[match[1]] = match[2];
  }
  return params_obj;
};

var param_page = getParams()['page']
if (param_page == null) param_page = '1';
var page = sessionStorage.getItem('trainings-page');
if (page == null) page = '1';

if (param_page != page) {
    sessionStorage.setItem('trainings-page', param_page);
    sessionStorage.setItem('trainings-scroll_v', 0);
}

let scroll_v = sessionStorage.getItem('trainings-scroll_v');             
if (scroll_v)
    window.scrollTo(0, scroll_v);
